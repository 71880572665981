export const LOGIN_URL = 'https://pacificbusinesstrust.force.com'

export const CLIENT_SALESFORCE_URL =
  'https://ap6.salesforce.com/servlet/servlet.WebToLead'

export const SP_SALESFORCE_URL =
  'https://ap6.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'

export const MAILCHIMP_URL =
  'https://pacificbusiness.us9.list-manage.com/subscribe/post?u=cc2ca649baa1cd6457ba5a141&amp;id=90c0c3e294'

if (!process.env.GATSBY_LAMBDA_URL) {
  throw new Error('GATSBY_LAMBDA_URL is not defined')
}

export const LAMBDA_URL = process.env.GATSBY_LAMBDA_URL
