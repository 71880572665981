import { Link } from 'gatsby'
import * as React from 'react'
import { MouseEvent, ReactNode } from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'

/**
 * Visual style options for the button component
 * - Primary: Main call-to-action style
 * - Secondary: Alternative, less prominent style
 */
export type ButtonVisualStyle = 'Primary' | 'Secondary'

/**
 * Visual type options for the button component
 * - Solid: Filled background
 * - Outline: Bordered with transparent background
 */
export type ButtonVisualType = 'Solid' | 'Outline'

/**
 * Common properties shared between all button variants
 */
type CommonProps = {
  /** Content to be rendered inside the button */
  children: ReactNode
  /** Whether the button is in an active state */
  active?: boolean
  /** Whether the button is disabled */
  disabled?: boolean
  /** Use alternative styling */
  alternative?: boolean
  /** Additional inline styles */
  style?: object
  /** Visual style variant */
  visualStyle?: ButtonVisualStyle
  /** Visual type variant */
  visualType?: ButtonVisualType
  /** Aria label for accessibility */
  'aria-label'?: string
}

/**
 * Properties specific to HTML button elements
 */
type ButtonElement = {
  /** HTML button type */
  type?: 'button' | 'submit' | 'reset'
  /** Click event handler */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  href?: undefined
  to?: undefined
}

/**
 * Properties specific to anchor elements
 */
type AnchorElement = {
  /** URL for anchor tag */
  href: string
  /** Click event handler */
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  /** Target attribute for anchor tag */
  target?: string
  /** Rel attribute for anchor tag */
  rel?: string
  type?: undefined
  to?: undefined
}

/**
 * Properties specific to Gatsby Link elements
 */
type GatsbyLinkElement = {
  /** Internal route for Gatsby Link */
  to: string
  /** Click event handler */
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  type?: undefined
  href?: undefined
}

/** Union type combining all possible button props */
type ButtonProps = CommonProps &
  (ButtonElement | AnchorElement | GatsbyLinkElement)

// Base styled component for the button
const BaseButton = styled.button<CommonProps>`
  /* Layout */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  /* Colors */
  background-color: ${(props): string =>
    props.visualType === 'Outline'
      ? 'transparent'
      : props.visualStyle === 'Secondary'
        ? theme.colours.white
        : theme.colours.quaternary};
  color: ${(props): string =>
    props.visualType === 'Outline'
      ? props.visualStyle === 'Secondary'
        ? theme.colours.white
        : theme.colours.quaternary
      : props.visualStyle === 'Secondary'
        ? theme.colours.darkGrey
        : theme.colours.white};
  
  /* Typography */
  font-family: ${theme.fonts.primaryBold};
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  
  /* Spacing & Shape */
  padding: 1rem 1.8rem;
  border-radius: 0.8rem;
  border-width: ${(props): string => (props.visualType === 'Outline' ? '1px' : '0')};
  border-color: ${(props): string =>
    props.visualStyle === 'Secondary'
      ? theme.colours.white
      : theme.colours.quaternary};
  border-style: solid;
  
  /* Interaction */
  cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
  transition: background-color 0.2s ease, color 0.2s ease;

  /* States */
  &:hover {
    background-color: ${(props): string =>
      props.visualType === 'Outline'
        ? props.visualStyle === 'Secondary'
          ? theme.colours.white
          : theme.colours.quaternaryHover
        : theme.colours.quaternaryHover};
    color: ${(props): string =>
      props.visualType === 'Outline'
        ? props.visualStyle === 'Secondary'
          ? theme.colours.quaternary
          : theme.colours.white
        : theme.colours.white};
  }

  &:active {
    background-color: ${(props): string =>
      props.visualType === 'Outline'
        ? props.visualStyle === 'Secondary'
          ? theme.colours.white
          : theme.colours.quaternaryHover
        : theme.colours.quaternaryHover};
    color: ${(props): string =>
      props.visualType === 'Outline'
        ? props.visualStyle === 'Secondary'
          ? theme.colours.quaternary
          : theme.colours.white
        : theme.colours.white};
  }

  &:disabled {
    background-color: ${theme.colours.gray2};
    color: ${theme.colours.white};
    pointer-events: none;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  /* Responsive styles */
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
  }

  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    font-size: 1.8rem;
  }
`

// Alternative button style
const AltButton = styled(BaseButton)`
  height: 4.4rem;
  line-height: 4.4rem;
  background-color: ${theme.colours.danger1};
  color: ${theme.colours.white};

  &:hover {
    background-color: ${theme.colours.white};
    color: ${theme.colours.danger1};
  }

  &:active {
    background-color: ${theme.colours.white};
    color: ${theme.colours.danger1};
  }
`

/**
 * Button component that can render as a button, anchor, or Gatsby Link
 * Supports different visual styles, types, and states
 *
 * @example
 * // Basic button
 * <Button onClick={() => console.log('clicked')}>Click me</Button>
 *
 * // Link button
 * <Button href="https://example.com">External Link</Button>
 *
 * // Gatsby internal link
 * <Button to="/about">About Page</Button>
 */
const Button = (props: ButtonProps): JSX.Element => {
  const {
    children,
    alternative,
    visualType = 'Solid',
    visualStyle = 'Primary',
    'aria-label': ariaLabel,
    ...restProps
  } = props

  // Select the appropriate styled component based on alternative prop
  const Component = alternative ? AltButton : BaseButton

  // Render as Gatsby Link
  if ('to' in props && props.to) {
    return (
      <Component
        as={Link}
        visualType={visualType}
        visualStyle={visualStyle}
        aria-label={ariaLabel}
        role='link'
        {...(restProps as GatsbyLinkElement)}
      >
        {children}
      </Component>
    )
  }

  // Render as anchor tag
  if ('href' in props && props.href) {
    return (
      <Component
        as='a'
        visualType={visualType}
        visualStyle={visualStyle}
        aria-label={ariaLabel}
        role='link'
        {...(restProps as AnchorElement)}
      >
        {children}
      </Component>
    )
  }

  // Render as button
  return (
    <Component
      visualType={visualType}
      visualStyle={visualStyle}
      aria-label={ariaLabel}
      role='button'
      {...(restProps as ButtonElement)}
    >
      {children}
    </Component>
  )
}

// Default props
Button.defaultProps = {
  visualType: 'Solid',
  visualStyle: 'Primary',
  type: 'button',
  disabled: false,
  alternative: false,
}

export default Button
